import React, { Fragment, useEffect, useState } from 'react';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Button, { LoadingButton } from '@atlaskit/button';
import { DatePicker } from '@atlaskit/datetime-picker';
import InlineMessage from '@atlaskit/inline-message';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { useCookies } from 'react-cookie';
import moment from 'moment';

import guid from '../../utils/guid';

import { sumFromArrayOfObjects } from '../../helpers';

import { ReactComponent as Logo } from './media/logo.svg';

import useSharedContext from '../../hooks/useSharedContext';
import useNumberToWords from '../../hooks/useNumberToWords';

import './index.scss';

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const fields = [
    {
        label: 'Name',
        name: 'name',
        required: true,
    },
    {
        label: 'Note',
        name: 'note',
    },
    {
        type: 'date',
        label: 'Date',
        name: 'date',
        required: true,
        defaultValue: moment().format('YYYY-MM-DD').toString(),
    },
    {
        type: 'row',
    },
    {
        label: '_PRICE_ in words',
        name: 'price',
        required: true,
    }
];

const itemFields = [
    {
        label: 'Procedure',
        name: 'procedure',
        required: true,
    },
    {
        label: 'Price',
        name: 'price',
        type: 'number',
        required: true,
    },
    {
        label: 'Quantity',
        name: 'quantity',
        type: 'number',
    },
    {
        label: 'Tooth',
        name: 'tooth',
    },
];

const cookieName = 'isAuthToken';

const Questionnaire = () => {
    const {
        loading,
        setData,
        setLoading,
        authenticated,
        setAuthenticated,
    } = useSharedContext();

    const [total, setTotal] = useState(0);
    const [localData, setLocalData] = useState({});
    const [items, setItems] = useState([itemFields]);
    const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setAuthenticated(!!cookies[cookieName]);
    }, [cookies]); // eslint-disable-line

    const output = useNumberToWords(total);

    const fieldRenderer = (field, index) => (
        <Field
            key={field.name}
            label={field.label.replace('_PRICE_', total ? total : 'Price')}
            isRequired={field.required}
            name={`${index ? `${field.name}_${index}` : field.name}`}
            defaultValue={!index && field.name === 'price' ? output : (field.defaultValue || '')}
        >
            {({ fieldProps }) => field.type === 'date' ? (
                <DatePicker
                    dateFormat="DD-MM-YYYY"
                    weekStartDay={1}
                    {...fieldProps}
                />
            ) : (
                <TextField
                    type={field.type || 'text'}
                    {...fieldProps}
                />
            )}
        </Field>
    );

    const constructFormData = (data, set) => {
        const modifiedData = {
            whom: {
                name: data.name,
                note: data.note,
            },
            date: moment(data.date).format('DD-MM-YYYY').toString(),
            items: items.map((_, index) => ({
                procedure: data[`procedure_${index + 1}`],
                tooth: data[`tooth_${index + 1}`],
                price: parseFloat(`${data[`price_${index + 1}`]}`),
                quantity: parseFloat(`${data[`quantity_${index + 1}`]}`),
            })),
            price: data.price,
        };
        setLocalData(val => ({
            ...val,
            ...modifiedData,
        }));
        set && setData(modifiedData);
    };

    useEffect(() => {
        !!localData?.items?.length && setTotal(sumFromArrayOfObjects(localData?.items, 'price'));
    }, [localData?.items]);

    return (
        <ul className="q-page-holder">
            <li>
                <Logo/>
            </li>
            <li>
                {authenticated ? (
                    <Form
                        onSubmit={data => {
                            setLoading(true);
                            constructFormData(data, true);
                        }}
                    >
                        {({ formProps, getValues }) => (
                            <form
                                {...formProps}
                                onChange={() => constructFormData(getValues())}
                            >
                                {fields.map((field, index) => (
                                    <Fragment key={index}>
                                        {field.type === 'row' ? (
                                            <div className="sub-group">
                                                {items.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="sub-children-holder"
                                                    >
                                                        <ul>
                                                            <li>
                                                                {`Procedure ${index + 1}`}
                                                            </li>
                                                            <li>
                                                                {items.length > 1 && (
                                                                    <button onClick={() => setItems(val => val.filter((_, i) => i !== index))}>
                                                                        <EditorCloseIcon size="small"/>
                                                                    </button>
                                                                )}
                                                            </li>
                                                        </ul>
                                                        {item.map(field => fieldRenderer(field, (index + 1)))}
                                                    </div>
                                                ))}
                                                <Button
                                                    shouldFitContainer
                                                    appearance="default"
                                                    onClick={() => setItems(val => [...val, itemFields])}
                                                >
                                                    Add a Procedure
                                                </Button>
                                            </div>
                                        ) : fieldRenderer(field)}
                                    </Fragment>
                                ))}
                                <LoadingButton
                                    type="submit"
                                    appearance="primary"
                                    shouldFitContainer
                                    isLoading={loading}
                                >
                                    Generate
                                </LoadingButton>
                                <Button
                                    shouldFitContainer
                                    appearance="default"
                                    onClick={() => removeCookie(cookieName, { path: '/' })}
                                >
                                    Logout
                                </Button>
                            </form>
                        )}
                    </Form>
                ) : (
                    <Form
                        onSubmit={data => {
                            if (data.password === '5WKc5^#N%yshF!zk') {
                                setCookie(cookieName, guid(), { expires: tomorrow, path: '/' });
                            } else {
                                setHasError(true);
                            }
                        }}
                    >
                        {({ formProps }) => (
                            <form {...formProps}>
                                {fieldRenderer({
                                    required: true,
                                    type: 'password',
                                    name: 'password',
                                    label: 'Password',
                                    placeholder: 'Enter your password',
                                })}
                                {hasError && (
                                    <InlineMessage
                                        type="error"
                                        title="Password is wrong"
                                    />
                                )}
                                <LoadingButton
                                    type="submit"
                                    appearance="primary"
                                    shouldFitContainer
                                    isLoading={loading}
                                >
                                    Log in
                                </LoadingButton>
                            </form>
                        )}
                    </Form>
                )}
            </li>
        </ul>
    );
};

export default Questionnaire;
