import React, { useState } from 'react';

import Invoice from './containers/invoice';
import Questionnaire from './containers/questionnaire';

import SharedContext from './sharedContext';

const App = () => {
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    const value = {
        data,
        setData,
        loading,
        setLoading,
        authenticated,
        setAuthenticated,
    };

    return (
        <SharedContext.Provider value={value}>
            <Questionnaire/>
            {data && (
                <Invoice/>
            )}
        </SharedContext.Provider>
    );
}

export default App;
