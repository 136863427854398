import { useEffect, useState } from 'react';
/* eslint-disable */
const triConvert = num => {
    var ones = new Array('', 'մեկ', 'երկու', 'երեք', 'չորս', 'հինգ', 'վեց', 'յոթ', 'ութ', 'ինը', ' տաս', ' տասնմեկ', ' տասներկու', ' տասներեք', ' տասնչորս', ' տասնհինգ', ' տասնվեց', ' տասնյոթ', ' տասնութ', ' տասնինը');
    var tens = new Array('', '', ' քսան', ' երեսուն', ' քառասուն', ' հիսուն', ' վաթսուն', ' յոթանասուն', ' ութանասուն', ' ինսուն');
    var hundred = ' հարյուր';
    var output = '';
    var numString = num.toString();

    if (num == 0) {
        return 'dontAddBigSufix';
    }
    //the case of 10, 11, 12 ,13, .... 19
    if (num < 20) {
        output = ones[num];
        return output;
    }

    //100 and more
    if (numString.length == 3) {
        output = ones[parseInt(numString.charAt(0))] + hundred;
        output += tens[parseInt(numString.charAt(1))];
        output += ones[parseInt(numString.charAt(2))];
        return output;
    }

    output += tens[parseInt(numString.charAt(0))];
    output += ones[parseInt(numString.charAt(1))];

    return output;
};

const update = numString => {
    var bigNumArry = new Array('', ' հազար', ' միլիոն', ' միլիարդ', ' տրիլիոն', ' քվադրիլիոն', ' քվանտիլիոն');

    var output = '';
    var finlOutPut = new Array();

    if (numString == '0') {
        return '';
    }

    if (numString == 0) {
        return '';
    }

    var i = numString.length;
    i = i - 1;

    //cut the number to grups of three digits and add them to the Arry
    while (numString.length > 3) {
        var triDig = new Array(3);
        triDig[2] = numString.charAt(numString.length - 1);
        triDig[1] = numString.charAt(numString.length - 2);
        triDig[0] = numString.charAt(numString.length - 3);

        var varToAdd = triDig[0] + triDig[1] + triDig[2];
        finlOutPut.push(varToAdd);
        i--;
        numString = numString.substring(0, numString.length - 3);
    }
    finlOutPut.push(numString);
    finlOutPut.reverse();

    //conver each grup of three digits to english word
    //if all digits are zero the triConvert
    //function return the string "dontAddBigSufix"
    for (let j = 0; j < finlOutPut.length; j++) {
        finlOutPut[j] = triConvert(parseInt(finlOutPut[j]));
    }

    var bigScalCntr = 0; //this int mark the million billion trillion... Arry

    for (let b = finlOutPut.length - 1; b >= 0; b--) {
        if (finlOutPut[b] != 'dontAddBigSufix') {
            finlOutPut[b] = finlOutPut[b] + bigNumArry[bigScalCntr] + ' ';
            bigScalCntr++;
        } else {
            //replace the string at finlOP[b] from "dontAddBigSufix" to empty String.
            finlOutPut[b] = ' ';
            bigScalCntr++; //advance the counter
        }
    }

    //convert The output Arry to , more printable string
    for (let n = 0; n < finlOutPut.length; n++) {
        output += finlOutPut[n];
    }

    return output;//print the output
};

const useNumberToWords = number => {
    const [output, setOutput] = useState('');

    useEffect(() => {
        const result = update(`${number}`);
        const startResult = result[0] === ' ' ? result.slice(1) : result;
        setOutput(startResult[startResult.length - 1] === ' ' ? startResult.slice(0, -1) : startResult);
    }, [number]);

    return output;
};
/* eslint-enable */
export default useNumberToWords;
