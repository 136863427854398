import React, { createRef, useEffect } from 'react';
import ReactToPdf from 'react-to-pdf';

import useSharedContext from '../../hooks/useSharedContext';

import { getRandomArbitrary, getRandomInt, sumFromArrayOfObjects } from '../../helpers';

import logo from './media/logo.png';

import mark_1 from './media/mark_1.png';
import mark_2 from './media/mark_2.png';
import mark_3 from './media/mark_3.png';
import mark_4 from './media/mark_4.png';
import signature_1 from './media/signature_1.png';
import signature_2 from './media/signature_2.png';
import signature_3 from './media/signature_3.png';
import signature_4 from './media/signature_4.png';

import './index.scss';

const marks = [mark_1, mark_2, mark_3, mark_4];
const signatures = [signature_1, signature_2, signature_3, signature_4];

const Download = props => {
    const {
        toPdf,
    } = props;

    useEffect(() => {
        toPdf();
    }, [toPdf]);

    return null;
};

const Invoice = () => {
    const {
        data,
        setData,
        setLoading,
    } = useSharedContext();

    const ref = createRef();

    const mark = marks[getRandomInt(4)];
    const signature = signatures[getRandomInt(4)];

    const style = {
        transform: `skew(-${getRandomArbitrary(-0.5, 0.5)}deg, -${getRandomArbitrary(-0.5, 0.5)}deg)`,
    };

    return !!data && (
        <div className="pdf-generator-holder">
            <ReactToPdf
                targetRef={ref}
                filename={`${data?.date} ${data?.whom?.name}.pdf`}
                options={{
                    unit: 'pt',
                    compress: true,
                    format: [918, 1188],
                }}
                onComplete={() => {
                    setData(null);
                    setLoading(false);
                }}
            >
                {({ toPdf }) => (
                    <Download toPdf={toPdf}/>
                )}
            </ReactToPdf>
            <div
                ref={ref}
                className="print-page"
            >
                {Array(3).fill('').map((_, index) => (
                    <div
                        key={index}
                        style={style}
                        className="print-content"
                    >
                        <div className="logo">
                            <img src={logo} alt=""/>
                        </div>
                        <h2>Ստոմատոլոգիական Կլինիկա</h2>
                        <hr/>
                        <div className="details">
                            <p>Հայաստանի Հանրապետություն</p>
                            <p>Երևան, Չարենց 25</p>
                            <p>Հեռ․ +374 55 56 98 40</p>
                            <p>
                                <span>Տրվել է՝ {data?.date}</span>
                            </p>
                        </div>
                        <h3>
                            <span>Տեղեկանք</span>
                        </h3>
                        <div className="main-info">
                            <p>Տրվում է {data?.whom?.name}ին{data?.whom?.note ? ` (${data?.whom?.note})` : ''}, առ այն, որ նա բուժվել է ԱՁ «Ինեսսա Սաղեյան» ստոմատոլոգիական կլինիկայում։</p>
                            <p>Կատարվել է՝</p>
                            <table>
                                <tbody>
                                <tr>
                                    <td>N</td>
                                    <td>Բժշկական ծառայության անվանումը</td>
                                    <td>Ատամ</td>
                                    <td>Միավորի գինը (դրամ)</td>
                                    <td>Քանակը</td>
                                    <td>Ընդհանուր արժեքը (դրամ)</td>
                                </tr>
                                {data?.items?.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row.procedure}</td>
                                        <td>{row.tooth}</td>
                                        <td>{row.quantity ? row.price : ''}</td>
                                        <td>{isNaN(row.quantity) ? '' : row.quantity}</td>
                                        <td>{(row.quantity || 1) * row.price}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td/>
                                    <td>Ընդամենը</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td>{sumFromArrayOfObjects(data?.items, 'price')}</td>
                                </tr>
                                </tbody>
                            </table>
                            <p>Գումարը բառերով՝ {data?.price} դրամ։</p>
                        </div>
                        <ul className="mark-holder">
                            <li>
                                <div>Տնօրեն՝</div>
                                <div className="line">
                                    <div className="footnote">Կ.Տ.</div>
                                    {index === 2 && (
                                        <>
                                            <img
                                                alt=""
                                                src={mark}
                                                className="mark"
                                                style={{
                                                    transform: `translate(-60%, -53%) rotate(${getRandomArbitrary(-20, 20)}deg)`,
                                                    marginTop: `${getRandomArbitrary(-20, 20)}px`,
                                                    marginLeft: `${getRandomArbitrary(-20, 20)}px`,
                                                }}
                                            />
                                            <img
                                                alt=""
                                                src={signature}
                                                className="signature"
                                                style={{
                                                    marginTop: `${getRandomArbitrary(-20, 20)}px`,
                                                    marginLeft: `${getRandomArbitrary(-20, 20)}px`,
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                                <div>Ի. Սաղեյան</div>
                            </li>
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Invoice;
